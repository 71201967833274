<template>
  <div>
    <v-form v-if="!_.isEmpty(selectedCurrency) && !_.isEmpty(projectsToInvest)" ref="formAddInvestments" v-model="formAddInvestmentsValid">
      <v-row>
        <v-col v-for="(project, i) in projects" :key="i" cols="12" md="6">
          <v-card class="mb-3" height="100%">
            <div class="primary px-3 py-1">
              <b>Proyecto: </b>
              <router-link :to="`/proyectos/${project.id}`" target="_blank" class="black--text">
                {{ project.id }}
              </router-link>
            </div>
            <div class="primary px-3 py-1">
              <b>Prestataria: </b>
              <router-link :to="`/prestatarias/${project.borrower.id}`" target="_blank" class="black--text">
                {{ project.borrower.name }}
              </router-link>
            </div>
            <div class="primary px-3 py-1">
              <b>Partner: </b>
              <router-link :to="`/partners/${project.partner.id}`" target="_blank" class="black--text">
                {{ project.partner.name }}
              </router-link>
            </div>
            <div class="primary px-3 py-1"><b>Fecha de creación:</b> {{ formatDateL(project.created_at) }}</div>
            <div class="primary px-3 py-1"><b>Sector:</b> {{ project.sector }}</div>
            <div class="primary px-3 py-1">
              <b>Ya invertido por {{ investor.first_name }} {{ investor.last_name }}:</b>
              {{ investments.some((e) => e.project_id === project.id) ? "Sí" : "No" }}
            </div>
            <div class="primary px-3 py-1">
              <b>Número de créditos solicitados:</b>
              {{ project.borrower.num_credits ? project.borrower.num_credits : 0 }}
            </div>

            <v-card-text>
              <div
                v-if="
                  commissionIsInCurrentVersion(project) &&
                  (investor.investor.type === 'RETAIL' || !!getPartnerExchangeRate(project.partner.id))
                "
              >
                <div class="d-flex">
                  <p>
                    Inversión máxima:
                    {{ maxAmountToInvestInFavoriteCurrency(project) }}
                    {{ currencySymbol(favoriteCurrency) }} ≈
                    {{ maxAmountToInvestInProjectCurrency(project) }}
                    {{ currencySymbol(project.currency) }}
                  </p>
                </div>
                <v-text-field
                  v-model.number="projectsToInvest[project.id].amount"
                  type="number"
                  label="Cantidad"
                  outlined
                  :suffix="suffix(project)"
                  class="amount_field mt-1 mb-n5"
                  :rules="[
                    (v) => !!v || v === 0 || 'Campo obligatorio',
                    (v) => v >= 0 || 'Introduce un valor positivo',
                    enoughFundsRule(project),
                    (v) => v <= maxAmountToInvestInFavoriteCurrency(project) || 'Máximo de inversión superado'
                  ]"
                >
                  <template slot="append">
                    <v-btn
                      x-small
                      class="ml-2"
                      @click="projectsToInvest[project.id].amount = maxAmountToInvestInFavoriteCurrency(project).value"
                    >
                      max.
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
              <div v-else class="text-center py-5 mb-0">
                <p v-if="!commissionIsInCurrentVersion(project)">
                  Este proyecto no puede ser invertido aún ya que la versión activa de <i>parámetros de inversión</i> no contiene la
                  comisión requerida para el partner de este proyecto
                  <br />
                  <b>Crea una nueva versión con la configuración necesaria en "Ajustes Generales"</b>
                </p>
                <p v-else>
                  Este proyecto no puede ser invertido aún ya que el inversor no tiene asignado un tipo de cambio para el partner del mismo
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-divider class="my-5" />
      <div class="text-right">
        <v-btn :disabled="!getTotalToInvest() > 0 || !formAddInvestmentsValid" @click="investMultipleProjects()">INVERTIR</v-btn>
      </div>
    </v-form>
    <v-col v-else>
      <v-card flat class="secondary">
        <p class="text-center mb-0 pa-3 text--secondary">
          <span v-if="investor.investor.type === 'RETAIL'">{{
            !_.isEmpty(selectedCurrency) ? "No hay proyectos invertibles de la moneda seleccionada" : "Selecciona una moneda"
          }}</span>
          <span v-else>{{
            !_.isEmpty(selectedCurrency) ? "No hay proyectos invertibles del partner seleccionado" : "Selecciona un partner"
          }}</span>
        </p>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import CommonMixin from "@/mixins/CommonMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import _ from "lodash";
import * as currency from "currency.js";

export default {
  name: "ListProjectsToInvest",
  mixins: [CommonMixin, FormRulesMixin, FormatDateMixin],
  props: {
    investor: {
      type: Object,
      required: true
    },
    totalFunds: {
      type: Object,
      required: true
    },
    selectedCurrency: {
      type: String,
      required: true
    },
    projectsToInvest: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formAddInvestmentsValid: false
    };
  },
  computed: {
    ...mapState({
      currencies: (state) => state.currencies.currencies,
      projects: (state) => state.projects.projects,
      commissions: (state) => state.investments.commissions,
      investments: (state) => state.investments.investments
    }),
    _() {
      return _;
    },
    currency() {
      return currency;
    },

    favoriteCurrency() {
      return this.investor.investor.favorite_currency;
    }
  },
  methods: {
    suffix(project) {
      let amountInProjectCurrency = 0;

      if (this.investor.investor.type === "FUND") {
        amountInProjectCurrency = this.convertAmount(
          currency(this.projectsToInvest[project.id].amount),
          this.getPartnerExchangeRate(project.partner.id)
        );
      } else {
        amountInProjectCurrency = this.convertCurrency(
          currency(this.projectsToInvest[project.id].amount),
          this.favoriteCurrency,
          project.currency
        );
      }

      return `${this.currencySymbol(this.favoriteCurrency)} ≈ ${amountInProjectCurrency} ${this.currencySymbol(project.currency)}`;
    },

    async investMultipleProjects() {
      if (this.$refs.formAddInvestments.validate()) {
        const projectsToInvestFormattedData = this._.cloneDeep(this._.values(this.projectsToInvest));

        for (const project of projectsToInvestFormattedData) {
          const projectPartner = this.projects.find((e) => e.id === project.project_id).partner.id;
          let amountInProjectCurrency = 0;

          if (this.investor.investor.type === "FUND") {
            amountInProjectCurrency = this.convertAmount(currency(project.amount), this.getPartnerExchangeRate(projectPartner));
          } else {
            amountInProjectCurrency = this.convertCurrency(currency(project.amount), this.favoriteCurrency, this.selectedCurrency);
          }

          project.amount =
            amountInProjectCurrency.intValue >
            this.maxAmountToInvestInProjectCurrency(this.projects.find((e) => e.id === project.project_id)).intValue
              ? this.maxAmountToInvestInProjectCurrency(this.projects.find((e) => e.id === project.project_id)).intValue
              : amountInProjectCurrency.intValue;
        }

        await this.$store.dispatch(
          "projects/investMultipleProjects",
          projectsToInvestFormattedData.filter((e) => e.amount > 0)
        );
        this.$emit("invest");
      }
    },

    investorCommission(project) {
      return this.commissions.find((e) => e.key === this.investor.investor.commission).value.values[project.office.id];
    },

    currencySymbol(currency) {
      return this.currencies.find((e) => e.id === currency)?.symbol;
    },

    maxAmountToInvestInProjectCurrency(project) {
      const amount_invested_remaining = currency(project.amount_invested_remaining, { fromCents: true });
      const amount_invested_remaining_with_commission = amount_invested_remaining.multiply(1 + this.investorCommission(project) / 100);

      return amount_invested_remaining_with_commission;
    },

    maxAmountToInvestInFavoriteCurrency(project) {
      const amount_invested_remaining = currency(project.amount_invested_remaining, { fromCents: true });
      const amount_invested_remaining_with_commission = amount_invested_remaining.multiply(1 + this.investorCommission(project) / 100);

      if (this.investor.investor.type === "FUND") {
        return currency(amount_invested_remaining_with_commission.value * (1 / this.getPartnerExchangeRate(project.partner.id)));
      } else {
        return this.convertCurrency(currency(amount_invested_remaining_with_commission), this.selectedCurrency, this.favoriteCurrency);
      }
    },

    // Control max amount available
    enoughFundsRule(project) {
      let totalFundsInFavoriteCurrency = 0;

      if (this.investor.investor.type === "FUND") {
        totalFundsInFavoriteCurrency =
          currency(this.totalFunds.available_funds, { fromCents: true }).value * (1 / this.getPartnerExchangeRate(project.partner.id));
      } else {
        totalFundsInFavoriteCurrency = this.convertCurrency(
          currency(this.totalFunds.available_funds, { fromCents: true }),
          this.selectedCurrency,
          this.favoriteCurrency
        ).value;
      }

      let totalToInvestInFavoriteCurrency = 0;
      for (const projectId of this._.keys(this.projectsToInvest)) {
        totalToInvestInFavoriteCurrency += this.projectsToInvest[projectId].amount;
      }

      return totalToInvestInFavoriteCurrency <= totalFundsInFavoriteCurrency || "Saldo insuficiente";
    },

    // Get the exchange rate of a partner assigned to a fund investor
    getPartnerExchangeRate(partnerId) {
      return this.investor.investor.exchange_rates[partnerId];
    },

    // Obtain the sum of the investments to be made
    getTotalToInvest() {
      let total = 0;
      for (const projectId of this._.keys(this.projectsToInvest)) {
        total += this.projectsToInvest[projectId].amount;
      }

      return total;
    },

    // Check if investor commission in project partner is set to the current version
    commissionIsInCurrentVersion(project) {
      if (!this.commissions.some((e) => e.key === this.investor.investor.commission)) return false;
      else {
        const commissionObjByInvestor = this.commissions.find((e) => e.key === this.investor.investor.commission);
        return project.office.id in commissionObjByInvestor.value.values;
      }
    }
  }
};
</script>
